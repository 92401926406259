<template>
  <div class="learnPage">
    <div class="ziHead">
      <img :src="listDetail.fileName && listDetail.fileName !== '' ? listDetail.fileName : imgUrl " alt="">
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
        </div>

        <div class="navMainRight">
          <span>
            <router-link to="/"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
          </span>
          <a class="textTitle">{{ listDetail.name }} </a>
        </div>
      </div>
    </div>
    <div class="ziBase">
      <div class="ziContact">
        <div class="rowContact">
          <new-list ref="newList" :id="listId"></new-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getChannelInfo } from '@/api/common'
import newList from '@/components/newList/index.vue'
export default {
  components: {
    newList
  },
  data() {
    return {
      listDetail: '',
      imgUrl: require('../../assets/image/zihead.jpg'),
      listId: '',
      isDeail: false,
      isList: false,
      active: 0,
      pathtitle: '',
      mathedTitle: '学党史 悟思想',
      navSubList: []
    }
  },
  computed: {},
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    _this.getChannelInfo()
  },
  methods: {
    // 获取频道详情
    getChannelInfo() {
      var _this = this

      let data = {
        channelUuid: _this.listId
      }
      getChannelInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.listDetail = data
        }
      })
    },
  },
  
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.learnPage {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
}
.learnPage .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  // background: url(../../assets/image/zihead.jpg) center no-repeat;
  // background-size: 100% 100%;
}
.learnPage .ziHead img {
  width: 100%;
  height: 100%;
}

.learnPage .ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.learnPage .navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.learnPage .navMainLeft {
  width: 900px;
  float: left;
}
.learnPage .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
}
.learnPage .navMainLeft a:hover {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.learnPage .navMainLeft a.active {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.learnPage .navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 15px;
}
.learnPage .navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}

.learnPage .navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}
.learnPage .ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
}
.learnPage .ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.learnPage .rowContact {
  margin-top: 0px;
  padding-bottom: 20px;
  // background-color: rgb(206, 30, 30);
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
    font-size: 17px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
@media (max-width: 1300px) {
  .learnPage .navMain,
  .companyProfile {
    width: 1000px;
  }
  .learnPage .navMainLeft,
  .learnPage .navMainRight {
    width: auto;
  }
}
@media (max-width: 992px) {
  .learnPage .navMain,
  .companyProfile {
    width: 96%;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .learnPage .ziHead {
    height: 362px;
  }
}
@media (max-width: 768px) {
  .learnPage .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .learnPage .ziHead {
    height: 200px;
  }
  .learnPage .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
}
</style>
